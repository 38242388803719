import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";

export const WorkSection: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Box py={4} sx={{ paddingTop: '96px', height: isSmall ? '75vh' : '100vh' }}>
        <Grid container spacing={4}>
          <Grid container item direction="row">
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Iframe url="https://www.youtube.com/embed/kr-7e9kzn4k"
                  width={isSmall ? "464px" : "1152px"}
                  height={isSmall ? "261px" : "648px"}
                  id=""
                  className=""
                  display="block"
                  frameBorder={0}
                  position="relative"
                />
              </Grid>
              <Grid container direction="row">
                <Button variant="contained" href="/work" color="primary" disableElevation sx={{ width: isSmall ? "100%" : "25%", paddingTop: '10px', paddingBottom: '10px', marginTop: '32px', fontWeight: '600' }}>
                      View All Work
                </Button>
              </Grid>
          </Grid>
        </Grid>
      </Box>
    );
};

