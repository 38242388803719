import { Box, Button, Divider, Grid, Typography, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Email, YouTube, Phone, LinkedIn, Instagram, Twitter } from "@mui/icons-material";
import { useEffect } from "react";
import AOS from 'aos';
import "aos/dist/aos.css";

export const ContactSection: React.FC = () => {
    useEffect(() => {
      AOS.init({ duration: 1000 });
    }, []);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box py={4} sx={{ paddingTop: '96px' }}>
        <Grid container spacing={4}>
          <Grid data-aos="fade-up" container item direction="column">
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Typography variant="h2" sx={{ paddingBottom: '32px', fontWeight: '600', color: '#ffffff' }}>Contact Me</Typography>
              </Grid>
              <Divider sx={{ color: '#ffffff', borderWidth: '1px', borderColor: '#ffffff', marginBottom: '32px' }}/>
              <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ marginBottom: '32px' }}>
                <Email sx={{ color: '#ffffff' }}/> <Typography variant="h5" sx={{ marginLeft: '10px', fontWeight: '600', color: '#ffffff', fontSize: isSmall ? '1.25rem' : '1.5rem' }}>Email : <a href="mailto:devwelchm@gmail.com" style={{ color: '#ffffff' }}>devwelchm@gmail.com</a></Typography>
              </Grid>
              <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ marginBottom: '32px' }}>
                <Phone sx={{ color: '#ffffff' }}/> <Typography variant="h5" sx={{ marginLeft: '10px', fontWeight: '600', color: '#ffffff', fontSize: isSmall ? '1.25rem' : '1.5rem' }}>Phone : <a href="tel:3176073432" style={{ color: '#ffffff' }}>+1 (317)-607-3432</a></Typography>
              </Grid>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <IconButton href="https://www.linkedin.com/in/devinwelch/" target="_blank">
                  <LinkedIn sx={{ color: '#ffffff', fontSize: '3rem', marginLeft: '16px', marginRight: '16px' }}/>
                </IconButton>
                <IconButton href="https://www.youtube.com/channel/UC0nLCxv20LfpxzMUIApY_eg" target="_blank">
                  <YouTube sx={{ color: '#ffffff', fontSize: '3rem', marginLeft: '16px', marginRight: '16px' }}/>
                </IconButton>
                <IconButton href="https://twitter.com/devinnwelch" target="_blank">
                  <Twitter sx={{ color: '#ffffff', fontSize: '3rem', marginLeft: '16px', marginRight: '16px' }}/>
                </IconButton>
              </Grid>
          </Grid>
        </Grid>
      </Box>
    );
};
